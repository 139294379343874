import React from "react";

import Img from "gatsby-image";
function Heading({ data }) {
  return (
    <div>
      <div className="sucStrHeroSec">
        <h4 className="sucStrHeroSecTitle">{data.title}</h4>
        <h1 className="sucStrHeroSecHeading">{data.heading}</h1>
      </div>

      <div className="sucStrImg blogheadImg">
        <div className="sucStrImgCont">
          <div className="headImg2">
            <Img
              fixed={data.image.localFile.childImageSharp.fixed}
              alt="blog"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Heading;
