import React from "react";
import Layout from "../components/layoutnew";
import Heading from "../components/whyOpsio/successStoryDetail/Heading";
import SpeakerList from "../components/whyOpsio/successStoryDetail/SpeakerList";
import About from "../components/whyOpsio/successStoryDetail/About";
import ReadMore from "../components/resource/blog/detail/ReadMore";
import "../styles/successstoryblog.css";
import { graphql } from "gatsby";
import SEO from "../components/seo";
function SuccessStoryDetail({ data }) {
  const getData = data.allStrapiSuccessStoryList.edges[0].node;
  const aboutData = data.allStrapiSuccessStory.edges[0].node;
  return (
    <Layout slug={`successstories/${getData.slug}`} locale={getData.locale}>
      <SEO
        noindexForce={false}
        description={getData.metaDes}
        slug={`successstories/${getData.slug}`}
        locale={getData.locale}
        title={getData.heading}
      />
      <Heading data={getData} />
      <SpeakerList data={getData} />
      <About
        data={{
          title: getData.title,
          image: getData.image,
          aboutCompany: getData.aboutCompany,
          whyWork: getData.whyWork,
          aboutCompanyTitle: getData.aboutheading,
          whyWorkTitle: aboutData.whyWorkTitle,
        }}
      />
      <ReadMore
        slug={getData.slug}
        locale={getData.locale}
        data={aboutData.readMoreCloud}
      />
    </Layout>
  );
}

export default SuccessStoryDetail;
export const query = graphql`
  query SuccessStoryDetail($slug: String!, $locale: String!) {
    allStrapiSuccessStoryList(
      filter: { slug: { eq: $slug }, locale: { eq: $locale } }
    ) {
      edges {
        node {
          locale
          slug
          title
          aboutheading
          heading
          metaDes
          aboutCompany {
            data {
              aboutCompany
            }
          }

          whyWork {
            data {
              whyWork
            }
          }
          decription1 {
            data {
              decription1
            }
          }

          description2 {
            data {
              description2
            }
          }
          description3 {
            strapi_json_value {
              name
            }
          }
          description3_1 {
            data {
              description3_1
            }
          }
          description3_2 {
            data {
              description3_2
            }
          }
          description4 {
            data {
              description4
            }
          }
          description5 {
            data {
              description5
            }
          }
          heading5
          heading4
          quotation {
            strapi_json_value {
              author
              quote
            }
          }
          heading1
          heading2
          heading3
          id
          image {
            url
            localFile {
              childImageSharp {
                fixed {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
    allStrapiSuccessStory(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          slug
          locale
          readMoreCloud {
            cloudmigration {
              btn
              heading
              title
              slug
            }
          }
          aboutCompanyTitle
          whyWorkTitle
        }
      }
    }
  }
`;
