import React from "react";
import { Link } from "gatsby";

function ReadMore({ data, locale, slug }) {
  return (
    <div className="rsection5">
      {data.cloudmigration
        .filter((val) => decodeURI(val.slug) !== slug)
        .map((val, index) => (
          <div className="rsection5cont" key={index}>
            <div className="rsection5Title">{val.title}</div>
            <div className="rsection5Heading">{val.heading}</div>
            <div>
              <Link
                className="rsection5btn"
                to={`/successstories/${val.slug}/`}
              >
                {val.btn}
              </Link>
            </div>
          </div>
        ))}
    </div>
  );
}

export default ReadMore;
