import React from "react";
import ReactMarkdown from "react-markdown";
function SpeakerList({ data }) {
  return (
    <div>
      <div className="sucStrAbt sstoryWrap">
        <div className="sucStrAbtcont">
          <div className="sucStrAbttitle">
            <div className="sucStrAbttitleCont"> {data.heading1}</div>
          </div>
          <ReactMarkdown className="sucStrAbtDes">
            {data.decription1?.data?.decription1}
          </ReactMarkdown>
        </div>
        <div className="sucStrAbtcont">
          <div className="sucStrAbttitle">
            <div className="sucStrAbttitleCont"> {data.heading2}</div>
          </div>
          <ReactMarkdown className="sucStrAbtDes">
            {data.description2.data.description2}
          </ReactMarkdown>
        </div>
        {data.heading4 && data.description4 && (
          <div className="sucStrAbtcont">
            <div className="sucStrAbttitle">
              <div className="sucStrAbttitleCont">{data.heading4}</div>
            </div>
            <ReactMarkdown className="sucStrAbtDes">
              {data.description4.data.description4}
            </ReactMarkdown>
          </div>
        )}
        {data.heading3 && data.description3 && (
          <div className="sucStrAbtcont">
            <div className="sucStrAbttitle">
              <div className="sucStrAbttitleCont"> {data.heading3}</div>
            </div>
            <div className="sucStrAbtDes noBorder ">
              {data.description3.strapi_json_value.map((value, index) => (
                <div className="sucStrAbtList" key={index}>
                  <div className="sucStrInd">
                    {value.name && `0` + (index + 1)}
                  </div>

                  <div className="sucStrTitle">{value.name && value.name}</div>

                  <ReactMarkdown>
                    {index === 0
                      ? data.description3_1.data.description3_1
                      : data.description3_2.data.description3_2}
                  </ReactMarkdown>
                </div>
              ))}
            </div>
          </div>
        )}
        {data.quotation &&
          data.quotation.strapi_json_value.map(
            (item, index) =>
              item.quote &&
              item.author && (
                <blockquote key={index} className="otro-blockquote">
                  {item.quote}
                  <span>{item.author}</span>
                </blockquote>
              )
          )}
        {data.heading5 && data.description5 && (
          <div className="sucStrAbtcont">
            <div className="sucStrAbttitle">
              <div className="sucStrAbttitleCont">{data.heading5}</div>
            </div>
            <ReactMarkdown className="sucStrAbtDes">
              {data.description5.data.description5}
            </ReactMarkdown>
          </div>
        )}
      </div>
    </div>
  );
}

export default SpeakerList;
